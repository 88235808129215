import { useStaticQuery, graphql } from 'gatsby';

const useSanityConfigGlobal = () => {
    const { sanityConfigGlobal } = useStaticQuery(graphql`
        query headerConfigQuery {
            sanityConfigGlobal {
                headerConfig {
                    search_enabled
                    languages {
                        id
                        name
                        hreflang
                    }
                    menu {
                        id
                        language {
                            hreflang
                        }
                        items {
                            id
                            title
                            external
                            externalUrl
                            page {
                                slug {
                                    current
                                }
                            }
                        }
                    }
                }
                footerConfig {
                    description {
                        en
                        se
                    }
                    facebookUrl
                    instagramUrl
                    iosAppUrl
                    twitterUrl
                    youtubeUrl
                    tiktokUrl
                    linkedinUrl
                    androidAppUrl
                    menu {
                        id
                        language {
                            hreflang
                        }
                        items {
                            id
                            title
                            items {
                                id
                                title
                                external
                                externalUrl
                                page {
                                    slug {
                                        current
                                    }
                                }
                            }
                        }
                    }
                }
                seasonalTakeoverConfig {
                    enabled
                    countdownDate
                    youtubeVideoId
                    soldCardsMen
                    soldCardsWomen
                }
                leagueMenSponsorBanner {
                    desktopImage {
                        hotspot {
                            x
                            y
                        }
                        asset {
                            url
                            _id
                        }
                    }
                    mobileImage {
                        hotspot {
                            x
                            y
                        }
                        asset {
                            url
                            _id
                        }
                    }
                    url
                }
                matchlistAdsSidebar {
                    url
                    alt
                    external
                    image {
                        hotspot {
                            x
                            y
                        }
                        asset {
                            url
                            id
                        }
                    }
                    url
                }
                matchlistBanner {
                    image {
                        hotspot {
                            x
                            y
                        }
                        asset {
                            url
                            _id
                        }
                    }
                    subtitle {
                        en
                        se
                    }
                    title {
                        en
                        se
                    }
                    matchLink {
                        slug {
                            current
                        }
                    }
                }
                offseason
            }
        }
    `);

    return sanityConfigGlobal;
};

export default useSanityConfigGlobal;
