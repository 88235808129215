import * as React from 'react';

import Logo from 'components/misc/Logo';
import Menu from 'components/header/Menu';
// import Menu from 'components/header/MegaMenu'
import AccountLink from 'components/header/AccountLink';
import CustomLink from 'components/utils/CustomLink';
import LeagueBar from 'components/header/LeagueBar';
import Sponsors from 'components/header/Sponsors';
import ExternalLinks from 'components/header/ExternalLinks';
import * as style from 'styles/components/header/header.module.scss';

const Header = ({ isHomepage, headerConfig }) => (
    <header className={style.header}>
        <LeagueBar />

        <div className={style.header__container}>
            <div className={`bg ${style.header__sponsorBarContainer}`}>
                <div className={`wrapper ${style.header__sponsorWrapper}`}>
                    <Sponsors />
                    <ExternalLinks />
                </div>
            </div>
            <div className={`wrapper ${style.header__main} ${isHomepage ? 'homepage' : ''}`}>
                <div className={style.header__logo}>
                    <CustomLink to="/" isTranslated={false}>
                        <Logo white />
                    </CustomLink>
                </div>
                <div>
                    <div className={style.header__top}>
                        <AccountLink />
                    </div>
                    {/* <div>TODO search</div> */}
                    <div>
                        <Menu data={headerConfig.menu} />
                    </div>
                </div>
            </div>
        </div>
    </header>
);

export default Header;
